import React from "react";
import { Link } from "react-router-dom";

export default function Sidebar() {
  return (
    <sidebar>
      <div className="logo-main">
        <img src={require("../assets/images/logoside.svg").default} />
      </div>
      <div className="btm-btns">
        <div>
          <Link
            to="/dashboard"
            className={
              window.location.pathname == "/dashboard"
                ? "menu-btn bar_active"
                : "menu-btn "
            }
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.00214 15.9973C7.64795 15.9973 6.29279 15.9973 4.93859 15.9973C4.40725 15.9973 4.18497 15.7125 4.17814 15.0502C4.16937 14.1043 4.18984 13.1668 4.44333 12.2558C5.14431 9.73061 7.19266 8.06237 9.34922 8.27751C11.6706 8.50947 13.4927 10.6344 13.7755 13.4721C13.833 14.0538 13.8203 14.6476 13.8135 15.2353C13.8076 15.7101 13.5346 15.9949 13.1252 15.9961C11.7505 15.9997 10.3758 15.9973 9.00214 15.9973Z"
                fill="#C4C4C4"
              />
              <path
                d="M9.00897 1.56676e-05C10.7483 0.00722707 12.1473 1.74518 12.1405 3.89177C12.1337 6.01313 10.7093 7.75468 8.98947 7.74386C7.25603 7.73305 5.85114 5.98428 5.85796 3.84369C5.86576 1.71753 7.27845 -0.00599384 9.00897 1.56676e-05Z"
                fill="#C4C4C4"
              />
              <path
                d="M14.4423 9.37004C13.1573 9.37124 12.1044 8.07679 12.1034 6.49629C12.1025 4.91819 13.1544 3.61413 14.4345 3.60572C15.7224 3.59731 16.7802 4.90377 16.7783 6.49869C16.7763 8.08881 15.7341 9.37004 14.4423 9.37004Z"
                fill="#C4C4C4"
              />
              <path
                d="M3.5532 9.37004C2.26238 9.36763 1.22115 8.0852 1.2192 6.49629C1.21725 4.90257 2.27896 3.5949 3.56588 3.60572C4.84793 3.61654 5.89696 4.9206 5.89501 6.49869C5.89208 8.0828 4.84013 9.37244 3.5532 9.37004Z"
                fill="#C4C4C4"
              />
              <path
                d="M3.79986 10.3231C3.74624 10.4493 3.71407 10.5251 3.68092 10.6008C3.13788 11.8484 2.84052 13.1728 2.96044 14.5923C2.99944 15.0598 3.16615 15.5117 3.2773 15.9853C3.27535 15.9853 3.22172 15.9973 3.16908 15.9973C2.48272 15.9985 1.79636 16.0009 1.11001 15.9973C0.408049 15.9937 -0.0170258 15.4456 0.000523127 14.5743C0.0453704 12.2318 1.64037 10.3159 3.54151 10.3231C3.61268 10.3231 3.68385 10.3231 3.79986 10.3231Z"
                fill="#C4C4C4"
              />
              <path
                d="M14.7182 15.9889C14.8294 15.519 14.999 15.061 15.039 14.5863C15.1569 13.1668 14.8596 11.8423 14.3156 10.5948C14.2883 10.5311 14.259 10.4674 14.2337 10.4025C14.2288 10.3904 14.2356 10.3724 14.2395 10.3364C14.7533 10.2823 15.2476 10.3929 15.7273 10.6164C17.0688 11.245 17.9813 12.858 17.9999 14.6284C18.0086 15.436 17.5777 15.9901 16.9226 15.9961C16.2167 16.0033 15.5108 15.9985 14.805 15.9973C14.7611 15.9973 14.7182 15.9889 14.7182 15.9889Z"
                fill="#C4C4C4"
              />
            </svg>
            Dashboard
          </Link>
          <Link
            to="/market-place"
            className={
              window.location.pathname == "/market-place"
                ? "menu-btn bar_active"
                : "menu-btn "
            }
          >
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.78771 7.42165C8.65405 8.96664 6.36383 8.92734 5.19846 7.45048C5.15882 7.49676 5.12271 7.54131 5.08307 7.58323C4.26475 8.45048 3.26851 8.79022 2.11459 8.44611C0.936005 8.09415 0.242777 7.26794 0.0384192 6.06008C-0.0699258 5.41728 0.0692459 4.79457 0.2278 4.17535C0.468273 3.23648 0.703464 2.29674 0.941295 1.35787C1.15446 0.515068 1.81422 0.000655025 2.69155 0.000655025C5.89698 -0.000218342 9.10241 -0.000218342 12.3078 0.000655025C13.157 0.000655025 13.8255 0.490614 14.0352 1.29236C14.3338 2.43648 14.6306 3.58233 14.8949 4.73518C15.131 5.76662 14.9892 6.73781 14.2669 7.56663C13.0856 8.9221 11.042 8.90201 9.88372 7.52821C9.85201 7.49152 9.81942 7.45746 9.78771 7.42165Z"
                fill="#C4C4C4"
              />
              <path
                d="M9.80268 15.999C9.80268 15.04 9.80356 14.1064 9.80092 13.1728C9.80092 13.0662 9.793 12.9579 9.77009 12.8549C9.65911 12.3588 9.22397 12.0051 8.70955 12.0007C7.90533 11.9955 7.10111 11.9955 6.29689 12.0007C5.67676 12.0051 5.19493 12.4889 5.19229 13.1064C5.18877 13.999 5.19141 14.8916 5.19141 15.7841C5.19141 15.8488 5.19141 15.9134 5.19141 15.9903C5.12183 15.9938 5.06369 15.999 5.00555 15.999C4.13527 15.9999 3.26499 16.0007 2.3947 15.999C1.32623 15.9972 0.581021 15.261 0.58014 14.2051C0.579259 12.5274 0.58014 10.8496 0.58014 9.17101C0.58014 9.11424 0.58014 9.05747 0.58014 9.00856C0.901652 9.16664 1.19938 9.34918 1.52089 9.46621C2.70212 9.89678 3.84635 9.77014 4.93156 9.15965C5.13944 9.04262 5.26453 9.05572 5.46712 9.1649C6.8853 9.93433 8.29466 9.91424 9.68024 9.06795C9.71548 9.04612 9.75071 9.02341 9.78418 9.00158C11.2305 9.98411 13.0927 9.92123 14.3981 8.98498C14.4043 9.03651 14.4131 9.0793 14.4131 9.1221C14.414 10.8295 14.4166 12.5378 14.4122 14.2453C14.4096 15.2479 13.6573 15.9929 12.6478 15.9972C11.7538 16.0016 10.8588 15.9981 9.96476 15.9981C9.91896 15.999 9.87139 15.999 9.80268 15.999Z"
                fill="#C4C4C4"
              />
            </svg>
            Marketplace
          </Link>
          <Link to="#">
            <svg
              width="15"
              height="16"
              viewBox="0 0 15 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.78771 7.42165C8.65405 8.96664 6.36383 8.92734 5.19846 7.45048C5.15882 7.49676 5.12271 7.54131 5.08307 7.58323C4.26475 8.45048 3.26851 8.79022 2.11459 8.44611C0.936005 8.09415 0.242777 7.26794 0.0384192 6.06008C-0.0699258 5.41728 0.0692459 4.79457 0.2278 4.17535C0.468273 3.23648 0.703464 2.29674 0.941295 1.35787C1.15446 0.515068 1.81422 0.000655025 2.69155 0.000655025C5.89698 -0.000218342 9.10241 -0.000218342 12.3078 0.000655025C13.157 0.000655025 13.8255 0.490614 14.0352 1.29236C14.3338 2.43648 14.6306 3.58233 14.8949 4.73518C15.131 5.76662 14.9892 6.73781 14.2669 7.56663C13.0856 8.9221 11.042 8.90201 9.88372 7.52821C9.85201 7.49152 9.81942 7.45746 9.78771 7.42165Z"
                fill="#C4C4C4"
              />
              <path
                d="M9.80268 15.999C9.80268 15.04 9.80356 14.1064 9.80092 13.1728C9.80092 13.0662 9.793 12.9579 9.77009 12.8549C9.65911 12.3588 9.22397 12.0051 8.70955 12.0007C7.90533 11.9955 7.10111 11.9955 6.29689 12.0007C5.67676 12.0051 5.19493 12.4889 5.19229 13.1064C5.18877 13.999 5.19141 14.8916 5.19141 15.7841C5.19141 15.8488 5.19141 15.9134 5.19141 15.9903C5.12183 15.9938 5.06369 15.999 5.00555 15.999C4.13527 15.9999 3.26499 16.0007 2.3947 15.999C1.32623 15.9972 0.581021 15.261 0.58014 14.2051C0.579259 12.5274 0.58014 10.8496 0.58014 9.17101C0.58014 9.11424 0.58014 9.05747 0.58014 9.00856C0.901652 9.16664 1.19938 9.34918 1.52089 9.46621C2.70212 9.89678 3.84635 9.77014 4.93156 9.15965C5.13944 9.04262 5.26453 9.05572 5.46712 9.1649C6.8853 9.93433 8.29466 9.91424 9.68024 9.06795C9.71548 9.04612 9.75071 9.02341 9.78418 9.00158C11.2305 9.98411 13.0927 9.92123 14.3981 8.98498C14.4043 9.03651 14.4131 9.0793 14.4131 9.1221C14.414 10.8295 14.4166 12.5378 14.4122 14.2453C14.4096 15.2479 13.6573 15.9929 12.6478 15.9972C11.7538 16.0016 10.8588 15.9981 9.96476 15.9981C9.91896 15.999 9.87139 15.999 9.80268 15.999Z"
                fill="#C4C4C4"
              />
            </svg>
            Members
          </Link>
          <Link
            to="#"
            className={
              window.location.pathname == ""
                ? "menu-btn bar_active"
                : "menu-btn "
            }
          >
            <svg
              width="13"
              height="16"
              viewBox="0 0 13 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.21737 9.42841C8.21177 9.50134 8.20896 9.55292 8.20429 9.60451C8.17627 9.90244 8.27716 10.1541 8.55459 10.2964C8.90022 10.4743 9.25799 10.6326 9.62136 10.7767C10.5377 11.1386 11.4429 11.5175 12.2042 12.1445C12.8908 12.7101 13.1085 13.4252 12.9515 14.2558C12.8815 14.624 12.6377 14.8881 12.3229 15.0962C11.8128 15.4324 11.2299 15.5925 10.633 15.7099C9.39437 15.9518 8.13704 15.9945 6.87783 15.9989C5.68589 16.0025 4.49394 16.006 3.30947 15.8522C2.57992 15.757 1.85784 15.6281 1.18527 15.3266C0.676171 15.098 0.234329 14.7876 0.0792641 14.2407C-0.112232 13.5666 0.0437673 12.9574 0.541657 12.4371C0.944265 12.0164 1.43842 11.706 1.95966 11.4437C2.65091 11.0959 3.36178 10.7829 4.06331 10.4538C4.19969 10.3898 4.34075 10.3338 4.46592 10.2528C4.8377 10.0136 4.95447 9.61251 4.74989 9.23098C4.65181 9.04777 4.51543 8.88057 4.38372 8.71516C3.72422 7.89251 3.24782 6.9916 3.09182 5.95728C2.96385 5.10884 2.98346 4.26841 3.35898 3.46977C3.87275 2.37765 4.77698 1.78534 6.01377 1.63593C6.66392 1.55767 7.30286 1.59947 7.91471 1.82981C9.07864 2.26648 9.6989 3.1247 9.92589 4.26307C10.1482 5.37654 9.93616 6.43753 9.44388 7.45584C9.41585 7.51364 9.34393 7.57323 9.27947 7.59191C9.10573 7.64171 8.92637 7.67728 8.74702 7.70396C8.69004 7.71286 8.62465 7.67551 8.56393 7.6595C8.37897 7.61058 8.19682 7.53944 8.00906 7.51898C7.78674 7.49408 7.55975 7.51009 7.33462 7.51453C6.76574 7.5261 6.32203 7.9512 6.32483 8.4777C6.32763 8.99797 6.76574 9.41774 7.32528 9.42841C7.61766 9.43375 7.90818 9.42841 8.21737 9.42841Z"
                fill="#C4C4C4"
              />
              <path
                d="M12.2986 6.39662C12.2986 6.66254 12.3014 6.92934 12.2976 7.19526C12.2883 7.84626 11.6494 8.24024 11.0169 7.99123C10.9581 7.9681 10.8609 7.96721 10.8114 7.99923C10.1557 8.41011 9.42426 8.59954 8.65641 8.69381C8.57701 8.70359 8.50415 8.76318 8.42755 8.79698C8.29397 8.85656 8.16506 8.94728 8.02588 8.96417C7.79888 8.99263 7.56442 8.97662 7.33369 8.9704C7.04785 8.96328 6.83393 8.76585 6.81898 8.50527C6.8031 8.23757 6.98059 8.0099 7.26736 7.98589C7.5448 7.96188 7.82597 7.97255 8.10434 7.98411C8.18841 7.98767 8.2949 8.02591 8.34815 8.08461C8.45371 8.19844 8.5658 8.19578 8.70499 8.17799C9.31964 8.09706 9.90253 7.93342 10.4341 7.62126C10.5219 7.56967 10.5508 7.52432 10.5293 7.41493C10.4966 7.24595 10.506 7.06986 10.505 6.89644C10.5022 6.47399 10.5088 6.05155 10.5032 5.62822C10.4985 5.30183 10.6246 5.04659 10.9077 4.85716C10.9516 4.82781 10.9805 4.74243 10.9768 4.6864C10.8292 2.81432 9.31404 1.19926 7.38133 0.847079C4.95634 0.403293 2.65745 1.83693 2.11192 4.13145C2.0867 4.23817 2.07362 4.34845 2.0484 4.45517C1.99796 4.66861 2.00356 4.83937 2.23616 4.98256C2.42205 5.09728 2.49211 5.31517 2.49305 5.53395C2.49491 6.10669 2.50145 6.68032 2.49118 7.25306C2.48277 7.71197 2.10352 8.05437 1.62151 8.06326C1.13296 8.07216 0.722877 7.73598 0.707931 7.27441C0.689249 6.69011 0.695787 6.10491 0.706997 5.52061C0.711668 5.24402 0.859259 5.02969 1.09466 4.86338C1.15164 4.82336 1.20208 4.73976 1.20675 4.67395C1.36836 2.40077 3.22073 0.447761 5.5794 0.0733444C8.45651 -0.383781 11.0982 1.338 11.7026 4.06386C11.7241 4.15991 11.7437 4.25685 11.753 4.35467C11.7773 4.60369 11.7848 4.83937 12.0548 5.00568C12.2472 5.12396 12.2958 5.35875 12.2986 5.58109C12.3004 5.85234 12.2986 6.12448 12.2986 6.39662Z"
                fill="#C4C4C4"
              />
            </svg>
            Total Raised
          </Link>
          <Link
            to="/editprofile"
            className={
              window.location.pathname == "/editprofile"
                ? "menu-btn bar_active"
                : "menu-btn "
            }
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.344632 16C0.0680622 15.8719 -0.00514735 15.6512 0.000275581 15.3549C0.0551828 12.4838 2.23385 9.93214 5.04767 9.46894C5.3988 9.41104 5.7574 9.38652 6.11395 9.37903C6.74573 9.36541 7.37885 9.35587 8.00995 9.37971C10.4069 9.46826 12.1619 10.6113 13.3109 12.7059C13.763 13.5301 13.9596 14.4306 13.9989 15.3706C14.0111 15.6635 13.9237 15.8747 13.6552 16C9.2179 16 4.78127 16 0.344632 16Z"
                fill="#C4C4C4"
              />
              <path
                d="M6.99721 8.43697C4.68162 8.43561 2.7958 6.53514 2.80122 4.20827C2.80664 1.88752 4.69179 -0.0013616 7.00196 7.36468e-07C9.31823 0.00136308 11.2034 1.90115 11.198 4.2287C11.1925 6.54945 9.30738 8.43833 6.99721 8.43697Z"
                fill="#C4C4C4"
              />
            </svg>
            Profile
          </Link>
          <Link
            to="#"
            className={
              window.location.pathname == ""
                ? "menu-btn bar_active"
                : "menu-btn "
            }
          >
            <svg
              width="18"
              height="16"
              viewBox="0 0 18 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15.7468 15.9968C15.7468 15.7883 15.7468 15.6083 15.7468 15.4294C15.7468 14.7297 15.7479 14.03 15.7457 13.3303C15.7457 13.2372 15.7447 13.1398 15.7167 13.053C15.662 12.8815 15.5289 12.7958 15.3485 12.8064C15.1692 12.8169 15.0479 12.9143 15.0114 13.0922C14.9953 13.1694 14.9974 13.2499 14.9964 13.3293C14.9953 14.1295 14.9953 14.9309 14.9953 15.7311C14.9953 15.8095 14.9953 15.8867 14.9953 15.9852C14.8965 15.9894 14.8192 15.9958 14.7419 15.9968C12.2029 15.9979 9.66389 15.9979 7.12487 15.9968C6.70832 15.9968 6.59023 15.8793 6.59023 15.4686C6.59023 14.3868 6.58808 13.3049 6.5913 12.2231C6.59238 11.8568 6.46784 11.5435 6.19837 11.2937C4.19936 9.44546 2.64375 7.26167 1.27171 4.94239C0.918501 4.34536 0.581396 3.73776 0.251807 3.12803C-0.108916 2.46009 -0.0820769 1.79003 0.332325 1.15807C0.739213 0.538819 1.34471 0.251952 2.08978 0.300646C2.78224 0.346163 3.2997 0.690192 3.64862 1.28086C4.21976 2.2452 4.75441 3.23283 5.37172 4.16753C6.01264 5.13928 6.71584 6.07292 7.42655 6.99703C7.96119 7.69144 8.70841 8.03547 9.59518 8.04394C10.7364 8.05558 11.8776 8.04711 13.0199 8.04817C15.4065 8.05135 17.3851 9.61906 17.8854 11.9182C17.9573 12.2474 17.9863 12.5894 17.9917 12.927C18.0067 13.7855 17.997 14.644 17.996 15.5025C17.996 15.8698 17.8671 15.9979 17.4946 16C16.9245 15.9979 16.3555 15.9968 15.7468 15.9968Z"
                fill="#C4C4C4"
              />
              <path
                d="M14.6797 3.83832C14.67 5.95119 12.9222 7.66074 10.7847 7.64804C8.64399 7.63534 6.92089 5.91414 6.93056 3.79915C6.94022 1.70217 8.68694 -0.00633365 10.8137 1.76509e-05C12.9555 0.00636895 14.6893 1.72969 14.6797 3.83832Z"
                fill="#C4C4C4"
              />
            </svg>
            Support
          </Link>
        </div>
        <div>
          <button
            type="button"
            className="logout"
            onClick={() => {
              localStorage.clear();
              window.location.href = "/";
            }}
          >
            Logout
          </button>
        </div>
      </div>
    </sidebar>
  );
}
