import React from "react";
// import { Container } from "react-bootstrap";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import Layout from "../Components/Layout";

export default function Dashboard() {
  return (
    <>
      {" "}
      <Layout>
        <div className="top-bar d-flex">
          <img src={require("../assets/images/dash-white.svg").default} />
          <h2>Dashboard</h2>
        </div>

        {/* <div className="cmn-box">
          <h2>Welcome!</h2>
          <p>
            Lorem Ipsum is simply dummy text for printing and typesetting
            industry. Lorem Ipsum has been industry’s standard dummy text ever
            since 1500s, when an unknown printer took galley of type and
            scrambled it to make a type specimen book.
          </p>
        </div> */}
        <Container>
          <div className="dashboard-inner">
            <Row>
              <Col lg={6}>
                <div className="dahsboard-left-section">
                  <div className="dashboard-upper">
                    <h2>Hello and Welcome- Right let’s get you started</h2>
                    <p className="mb-0">Download your pro app here </p>
                    <p>Cluberly is for you and your club.</p>
                    <div className="d-flex align-items-center gap-20 mt-4 app_btns">
                      <a target="_blank" href="#" className="me-2">
                        <img
                          src="https://dev.bosselt.com/patch-port/public/v1/assets/images/app-logo.svg"
                          alt=""
                        />
                      </a>
                      <a target="_blank" href="#">
                        <img
                          src="https://dev.bosselt.com/patch-port/public/v1/assets/images/google-play-blck.svg"
                          alt=""
                        />
                      </a>
                    </div>
                    <p>
                      Please note to access this dashboard you will need to log
                      in via web browser- <a
                        href="https://cluberly-portal.bosselt.com/"
                        className="highlighted_link"
                      >
                        https://cluberly-portal.bosselt.com/
                      </a>
                    </p>
                    <p>And if you need any help get in touch </p>
                    <a href="info@cluberlyapp.com" className="highlighted_link">
                      info@cluberlyapp.com
                    </a>
                    <p>0800 123456</p>
                  </div>
                </div>
              </Col>
              <Col lg={6}>
                <div className="dashboard-right-side">
                  <img
                    src={require("../assets/images/welcome_banner.png")}
                    className="img-fluid"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </Layout>
    </>
  );
}
