import React, { useEffect, useState } from 'react'
import BuisnessSelect from './BuisnessSelect';
import ClubSelect from './ClubSelect';
import Registeration from './Registeration';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AddandupdatebyclubAction, categoryandsubcategorylistAction } from '../Redux/Action/Authaction';
import { clubdetailsAction } from '../Redux/Action/AdminstratorAction';
import { toast } from 'react-toastify';

// import { useDispatch } from 'react-redux';
export default function MasterRegistationfile() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});

    const nextStep = () => {
        setStep(step + 1);
      };

    //   const dispatch = useDispatch();
      const prevStep = () => {
        setStep(step - 1);
      };


      const navigate = useNavigate();
      const dispatch=useDispatch();
      const [category_id,setcategory_id]=useState("");
      const [sub_category_id,setsubcategory_id]=useState("");
      const [clubdeatils,setclubdeatils]=useState({});
      const { id } = useParams();
      useEffect(() => {
        dispatch(categoryandsubcategorylistAction());
      
        dispatch(clubdetailsAction({ id: id })).then(function (data) {
          if (data.payload) {
            var Details = data.payload;
            console.log(Details, "DetailsDetailsDetailsDetails");
            setclubdeatils(Details);
            setcategory_id(Details?.category_id);
            setsubcategory_id(Details?.subcategory_id);
            setFormData({
              ...formData,
              ["category_id"]: Details?.category_id,
              ["sub_category_id"]: Details?.subcategory_id,
            });
          }
        });
      }, [id]);
      
     
      const categorylist=useSelector((state)=>state.Administrator.categorysub)
      const submitfom=()=>{
        if( category_id==undefined || category_id==""){
          toast.error("Please select user type")
        }else{
          dispatch(AddandupdatebyclubAction({
            category_id:category_id,
            id:id
          })).then(function(){
            navigate('/clubselect/'+id)
          })
        }
       
      }







  switch(step){
    case 1:
        return (
            <BuisnessSelect  formData={formData} setFormData={setFormData} nextStep={nextStep} categorylist={categorylist} category_id={category_id} />
        )
    case 2:
        return (
            <ClubSelect  formData={formData} setFormData={setFormData} nextStep={nextStep}  prevStep={prevStep}  categorylist={categorylist}  sub_category_id={sub_category_id}  category_id={category_id}/>
        )

        case 3:
            return (
                <Registeration  formData={formData} setFormData={setFormData} nextStep={nextStep}  prevStep={prevStep}  clubdeatils={clubdeatils} />
            )
    case 4:
     

default:
    return null;
  }
}