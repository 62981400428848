import React, { useEffect, useMemo, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { useDispatch } from "react-redux";
import { AddandupdatebyclubAction } from "../Redux/Action/Authaction";
import { Decryptedid } from "../Util/BcruptEncyptid";
import { clubdetailsAction, memberprofiledetailsAction } from "../Redux/Action/AdminstratorAction";
import moment from "moment/moment";
export default function Registeration({ formData, setFormData, nextStep,clubdeatils}) {
  const dispatch=useDispatch();
  const { id } = useParams();
const navigate=useNavigate()
 
  useEffect(() => {
    dispatch(
      clubdetailsAction({
        id: id,
      })
    ).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
      
            formik.setFieldValue(key, value, { strict: false });
          
        }
      }
    });
  }, [id]);
  const formik=useFormik({
    initialValues:{
      firstname:"",
      lastname:"",
      clubname:"",
      address1:"",
      mobile:"",
      termcondition:"",
      email:"",
      password:"",
      confirmpassword:""
    },
    validationSchema:Yup.object().shape({
      email:Yup.string().email("Please enter a valid email").required('Email is required'),
      firstname:Yup.string().required('First name is required'),
      lastname:Yup.string().required('Last name is required'),
      clubname:Yup.string().required('Company name is required'),
      address1:Yup.string().required('Address is required'),
      termcondition:Yup.string().required('Terms and conditions is required'),
      
      mobile: Yup.string()
      .matches(/^[0-9]+$/, 'Phone number must contain only digits')
      .required('Phone number is required')
     ,
      // You can add more validation rules here if needed,
      password: Yup.string().required('Password is required').min(5, 'Your password is too short.').matches(/[a-zA-Z]/, 'Password can only contain Latin letters.'),
      confirmpassword: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
      values.id=id;
      values.category_id=formData.category_id
      values.subcategory_id=formData.subcategory_id
      
      values.invitedstatus=1;
      dispatch(AddandupdatebyclubAction(values)).then(function(data){
      
         navigate('/successregisteration/'+data?.payload?._id)
      })

    }
  })
  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={7} lg={8} md={9} className="white-sec">
              <Row>
                <Col lg={6}>
                  <div className="logo">
                    <img
                      src={require("../assets/images/Logo.png")}
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div className="profile-sec d-flex justify-content-end">
                  
                    <button type="button" onClick={()=>{
                      navigate('/')
                    }}>
                      Already a Cluberly member?{" "}
                      <img
                        src={require("../assets/images/profile.svg").default}
                      />
                    </button>
                  </div>
                </Col>
              </Row>

              <Row className="second-sec">
                <Col lg={6}>
                  <h2 className="cmn-heading">Input your information</h2>
                </Col>
                <Col lg={6}>
                  <p className="sub-heading">
                    Welcome to Cluberly!
                    <br /> We’re glad you’ve made it here. Register your <br />
                    company details here to create an account. <br />
                    Here are our <NavLink to="">terms and conditions. </NavLink>
                  </p>
                </Col>
              </Row>
              <hr className="cmn-border" />
              <form onSubmit={formik.handleSubmit}>
                <Row>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>First name</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                      
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="firstname"
                              value={formik.values.firstname}
                            />
                            {formik.submitCount > 0 && formik.errors.firstname && (
                              <div className="errors">{formik.errors.firstname}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label for="exampleInputPassword1">Last name</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                    
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="lastname"
                              value={formik.values.lastname}
                            />
                            {formik.submitCount > 0 && formik.errors.lastname && (
                              <div className="errors">{formik.errors.lastname}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Company Name</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                     
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="clubname"
                              value={formik.values.clubname}
                            />
                            {formik.submitCount > 0 && formik.errors.clubname && (
                              <div className="errors">{formik.errors.clubname}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Work email</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="email"
                        class="form-control"
                     
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="email"
                              value={formik.values.email}
                            />
                            {formik.submitCount > 0 && formik.errors.email && (
                              <div className="errors">{formik.errors.email}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Registered Address</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="search"
                        class="form-control"
                        placeholder="Search Here"
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="address1"
                              value={formik.values.address1}
                            />
                            {formik.submitCount > 0 && formik.errors.address1 && (
                              <div className="errors">{formik.errors.address1}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group mb-4">
                      <div className="d-flex justify-content-between">
                        <label>Phone number</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <div className="phone-select d-flex">
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>+44</option>
                        </select>
                        <input
                          type="text"
                          class="form-control"
                         
                          onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="mobile"
                              value={formik.values.mobile}
                            />
                         
                      </div>
                      {formik.submitCount > 0 && formik.errors.mobile && (
                              <div className="errors">{formik.errors.mobile}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <hr className="cmn-border" />
                  </Col>
                  <Col lg={6}>
                    <div class="form-group">
                      <div className="d-flex justify-content-between">
                        <label>Password</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder=""
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="password"
                              value={formik.values.password}
                            />
                            {formik.submitCount > 0 && formik.errors.password && (
                              <div className="errors">{formik.errors.password}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div class="form-group">
                      <div className="d-flex justify-content-between">
                        <label>Confirm password</label>
                        <span>
                          <img
                            src={require("../assets/images/info.svg").default}
                          />
                        </span>
                      </div>
                      <input
                        type="password"
                        class="form-control"
                        placeholder=""
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="confirmpassword"
                              value={formik.values.confirmpassword}
                            />
                            {formik.submitCount > 0 && formik.errors.confirmpassword && (
                              <div className="errors">{formik.errors.confirmpassword}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={12}>
                    <hr className="cmn-border" />
                  </Col>

                  <Col lg={6}>
                    <div class="form-check">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        id="exampleCheck1"

                        
                        onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="termcondition"
                              value={formik.values.termcondition}
                      />
                      <label
                        class="form-check-label sub-heading"
                        for="exampleCheck1"

                      >
                        I agree with <Link to="#">terms and conditions.</Link>
                      </label>
                      {formik.submitCount > 0 && formik.errors.termcondition && (
                              <div className="errors">{formik.errors.termcondition}</div>
                            )}
                    </div>
                  </Col>
                  <Col lg={6} className="d-flex justify-content-end">
                    
                      <button type="submit" class="cmn-btn">
                        Register
                      </button>
                 
                  </Col>
                </Row>
              </form>
            </Col>
            <Col xl={5} lg={4} md={3} className="cmn-background"></Col>
          </Row>
        </Container>

      </section>
    </div>
  );
}
