import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MarketPlace() {
  return (
    <Layout>
      <Container fluid>
        <div className="first-sec-market">
          <Row>
            <Col lg={6}>
              <div className="market-first">
                <h2>Raise More with Cluberly Marketplace</h2>
                <p>Branded Merchandise, exclusively from Cluberly.</p>
                <div className="market-btns">
                  <Link to="/product-overview">Activate Marketplace</Link>
                  <Link to="">Learn More</Link>
                </div>
              </div>
            </Col>
            <Col lg={6}>
              <img
                className="img-fluid"
                src={require("../assets/images/iPhone15.svg").default}
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={12}>
            <div className="market-second">
              <h2>How it works</h2>
              <p>
                Cluberly Marketplace helps to support and promote your Club,
                Charity or School by supplying joint branded kits, uniforms and
                other clothing. When a Cluberly member purchases a branded item
                from our marketplace, a percentage of the purchase price goes
                directly to your pot!It costs nothing to activate marketplace,
                so why not register now?
              </p>
            </div>
          </Col>
          <Row className="justify-content-center">
            <Col xxl={3} lg={4}>
              <div className="market-products text-center">
                <img src={require("../assets/images/image50.png")} />
                <p>T-Shirts</p>
              </div>
            </Col>
            <Col xxl={3} lg={4}>
              <div className="market-products text-center">
                <img src={require("../assets/images/image52.png")} />
                <p>Team Kits</p>
              </div>
            </Col>
            <Col xxl={3} lg={4}>
              <div className="market-products text-center">
                <img src={require("../assets/images/image53.png")} />
                <p>Accessories & More</p>
              </div>
            </Col>
          </Row>
        </Row>
        <Row className="mt-4 pt-4">
          <Col lg={6}>
            <img
              className="img-fluid foot-image-in"
              src={require("../assets/images/footshape.svg").default}
            />
          </Col>
          <Col lg={6} className="started-sec">
            <div className="market-last">
              <h2>Getting Started</h2>
            </div>
            <Row>
              <Col lg={3} xs={3}>
                <div className="icons-img">
                  <img src={require("../assets/images/Frame48.svg").default} />
                </div>
              </Col>
              <Col lg={9} xs={9}>
                <div className="icons-desc">
                  <h3>Check your terms</h3>
                  <p>
                    Check if your team, business or charity has permission to
                    publish branding on 3rd party products. Some businesses
                    don’t and therefore wouldn’t be available.{" "}
                  </p>
                </div>
              </Col>
              <Col lg={3} xs={3}>
                <div className="icons-img">
                  <img src={require("../assets/images/Frame35.svg").default} />
                </div>
              </Col>
              <Col lg={9} xs={9}>
                <div className="icons-desc">
                  <h3>Select products for purchase</h3>
                  <p>
                    Select which Cluberly products you’d like to be available
                    for your supporters and donators. If you’re a football team,
                    a branded school uniform might not be the right fit for you.
                  </p>
                </div>
              </Col>
              <Col lg={3} xs={3}>
                <div className="icons-img">
                  <img src={require("../assets/images/Frame43.svg").default} />
                </div>
              </Col>
              <Col lg={9} xs={9}>
                <div className="icons-desc">
                  <h3>Track your progress</h3>
                  <p>
                    Come back to your dashboard and see what your supporters are
                    purchasing and how much additional support you’ve gained
                    through the Marketplace!
                  </p>
                </div>
              </Col>
              <Col lg={12}>
                <Link to="/product-overview" className="act-btn">
                  Activate Marketplace
                </Link>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
}
