import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Registeration from "./Pages/Registeration";
import SuccessRegisteration from "./Pages/SuccessRegisteration";
import Login from "./Pages/Login";
import ForgotPassword from "./Pages/ForgotPassword";
import ChangePassword from "./Pages/ChangePassword";
import Dashboard from "./Pages/Dashboard";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import BuisnessSelect from "./Pages/BuisnessSelect";
import ClubSelect from "./Pages/ClubSelect";
import CharitySelect from "./Pages/CharitySelect";
import SchoolSelect from "./Pages/SchoolSelect";
import Profile from "./Pages/Profile";
import EditProfile from "./Pages/EditProfile";
import ClubDetails from "./Pages/ClubDetails";
import ClubLogo from "./Pages/ClubLogo";
import MasterRegistationfile from "./Pages/MasterRegistationfile";
import MarketPlace from "./Pages/MarketPlace";
import ProductOverview from "./Pages/ProductOverview";
import MarketPlaceSetting from "./Pages/MarketplaceSetting";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/:id" element={<Registeration />} />
          <Route
            path="/successregisteration/:id"
            element={<SuccessRegisteration />}
          />
          <Route path="/" element={<Login />} />
          <Route path="/forgotpassword" element={<ForgotPassword />} />
          <Route path="/changepassword" element={<ChangePassword />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route
            path="/buisnesselect/:id"
            element={<MasterRegistationfile />}
          />
          <Route path="/clubselect/:id" element={<ClubSelect />} />
          <Route path="/charityselect" element={<CharitySelect />} />
          <Route path="/schoolselect" element={<SchoolSelect />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="/editprofile" element={<EditProfile />} />
          <Route path="/clubdetails" element={<ClubDetails />} />
          <Route path="/clublogo" element={<ClubLogo />} />
          <Route path="/market-place" element={<MarketPlace />} />
          <Route path="/product-overview" element={<ProductOverview />} />
          <Route path="/marketplace-setting" element={<MarketPlaceSetting />} />
        </Routes>
        <ToastContainer />
      </BrowserRouter>
    </div>
  );
}

export default App;
