import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../Components/Layout";
import { useDispatch } from "react-redux";
import { clubdetailsAction } from "../Redux/Action/AdminstratorAction";
import { useFormik } from "formik";
import * as Yup from "yup";
export default function Profile() {
  const [file, setFile] = useState();
  const [upfile, setupFile] = useState("");
  const dispatch = useDispatch();
  function handleChange(e) {
    console.log(e.target.files);
    setFile(URL.createObjectURL(e.target.files[0]));
  }
  const navigate = useNavigate();
  function handleClick() {
    navigate("/editprofile");
  }

  useEffect(() => {
    dispatch(
      clubdetailsAction({
        id: localStorage.getItem("userid"),
      })
    ).then(function (data) {
      if (data.payload) {
        var Details = data.payload;
        for (const [key, value] of Object.entries(Details)) {
          if (key == "image") {
            setupFile(value);
          } else {
            formik.setFieldValue(key, value, { strict: false });
          }
        }
      }
    });
  }, [localStorage.getItem("userid")]);
  const formik = useFormik({
    initialValues: {
      firstname: "",
      lastname: "",
      companyrepresentative: "",
      address1: "",
      mobile: "",

      email: "",
      facebooklink: "",
      twitterlink: "",
      instralink: "",
      theradlink: "",
      color: "",
    },
    validationSchema: Yup.object().shape({
      email: Yup.string()
        .email("Please enter a valid email")
        .required("Email is required"),
      firstname: Yup.string().required("First name is required"),
      lastname: Yup.string().required("Last name is required"),
      companyrepresentative: Yup.string().required("Company name is required"),
      address1: Yup.string().required("Address is required"),
      mobile: Yup.number().required("Phone Number is required"),
      facebooklink: Yup.string(),
      twitterlink: Yup.string(),
      theradlink: Yup.string(),
      instralink: Yup.string(),
      color: Yup.string().required("Color is required"),
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: (values) => {
      values.id = localStorage.getItem("userid");
      values.invitedstatus = 1;
      values.image = upfile;
    },
  });

  return (
    <>
      <Layout>
        <div className="top-bar d-flex">
          <img src={require("../assets/images/dash-white.svg").default} />
          <h2>Dashboard</h2>
        </div>

        <div className="profile-tab">
          <Link
            to="/profile"
            className={
              window.location.pathname == "/profile"
                ? "profile-link active-tab"
                : "profile-link "
            }
          >
            <svg
              width="14"
              height="16"
              viewBox="0 0 14 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.344632 16C0.0680622 15.8719 -0.00514735 15.6512 0.000275581 15.3549C0.0551828 12.4838 2.23385 9.93214 5.04767 9.46894C5.3988 9.41104 5.7574 9.38652 6.11395 9.37903C6.74573 9.36541 7.37885 9.35587 8.00995 9.37971C10.4069 9.46826 12.1619 10.6113 13.3109 12.7059C13.763 13.5301 13.9596 14.4306 13.9989 15.3706C14.0111 15.6635 13.9237 15.8747 13.6552 16C9.2179 16 4.78127 16 0.344632 16Z"
                fill="#C4C4C4"
              />
              <path
                d="M6.99721 8.43697C4.68162 8.43561 2.7958 6.53514 2.80122 4.20827C2.80664 1.88752 4.69179 -0.0013616 7.00196 7.36468e-07C9.31823 0.00136308 11.2034 1.90115 11.198 4.2287C11.1925 6.54945 9.30738 8.43833 6.99721 8.43697Z"
                fill="#C4C4C4"
              />
            </svg>
            Basic Details
          </Link>
          <Link
            to="/clubdetails"
            className={
              window.location.pathname == "/clubdetails"
                ? "profile-link active-tab"
                : "profile-link"
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M10.1761 0.0236674C10.1761 0.0931988 10.1761 0.155411 10.1761 0.217623C10.1761 1.568 10.1761 2.91837 10.1761 4.26874C10.1761 5.21291 10.7876 5.82039 11.736 5.82039C13.0836 5.82039 14.4275 5.82039 15.775 5.82039C15.8409 5.82039 15.9105 5.82039 15.991 5.82039C15.9947 5.88992 15.9984 5.94482 15.9984 5.99971C15.9984 8.10029 16.002 10.2009 15.9984 12.3015C15.9947 14.0946 14.8229 15.5548 13.0872 15.9171C12.8273 15.972 12.5563 15.9939 12.2926 15.9939C10.1578 16.0013 8.0193 16.0013 5.88447 15.9976C3.00263 15.9939 0.64077 14.0397 0.0988236 11.2182C-0.344255 8.93101 0.732316 6.49375 2.73166 5.2678C2.86348 5.18729 2.91841 5.10312 2.91109 4.94576C2.9001 4.51393 2.90376 4.08211 2.90743 3.65028C2.92574 1.65583 4.42707 0.0822202 6.42276 0.0200079C7.64946 -0.0202472 8.87617 0.0126888 10.1029 0.0126888C10.1212 0.00902921 10.1395 0.0163483 10.1761 0.0236674ZM10.1761 10.2009C10.1798 7.79289 8.23901 5.83503 5.83687 5.82405C3.4274 5.81673 1.46101 7.75629 1.45003 10.1496C1.44271 12.5832 3.39078 14.5411 5.82222 14.5448C8.21338 14.5448 10.1724 12.5906 10.1761 10.2009Z"
                fill="#42505C"
              />
              <path
                d="M11.6445 4.35291C11.6445 3.06109 11.6445 1.77659 11.6445 0.510387C12.9261 1.79123 14.2114 3.07573 15.4894 4.35291C14.2297 4.35291 12.9481 4.35291 11.6445 4.35291Z"
                fill="#42505C"
              />
              <path
                d="M6.53627 11.6318C6.53627 11.8769 6.53994 12.1185 6.53627 12.3637C6.52529 12.7809 6.2177 13.0846 5.81124 13.0846C5.40478 13.0846 5.09718 12.7809 5.08986 12.36C5.08254 11.8733 5.08254 11.3829 5.08986 10.8962C5.09718 10.49 5.4121 10.1826 5.80391 10.1789C6.20671 10.1753 6.52163 10.4827 6.53261 10.8962C6.5436 11.145 6.53627 11.3902 6.53627 11.6318Z"
                fill="#42505C"
              />
              <path
                d="M5.81124 7.28055C6.4191 7.28055 6.90612 7.76727 6.90245 8.3711C6.89879 8.97126 6.40445 9.4653 5.80391 9.45798C5.20338 9.45432 4.71636 8.95662 4.72002 8.3528C4.72734 7.75995 5.21436 7.28055 5.81124 7.28055Z"
                fill="#42505C"
              />
            </svg>
            Club Details
          </Link>
          <Link
            to="/clublogo"
            className={
              window.location.pathname == "/clublogo"
                ? "profile-link active-tab"
                : "profile-link"
            }
          >
            <svg
              width="16"
              height="16"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7.98772 15.9918C6.32461 15.9918 4.66151 16.0123 3.00182 15.9884C0.795739 15.9542 -0.641973 13.6585 0.286905 11.6565C0.737684 10.6863 1.23286 9.73654 1.72462 8.7868C1.85439 8.54083 2.03197 8.30852 2.23004 8.11037C2.86523 7.4681 3.76678 7.3622 4.52491 7.85757C4.82543 8.05571 5.0679 8.34268 5.33085 8.59891C5.52209 8.78339 5.67918 9.00203 5.88749 9.15918C6.44414 9.58281 7.11348 9.53498 7.60865 9.04303C7.78623 8.8688 7.93308 8.65357 8.05943 8.43834C8.53753 7.60134 9.00197 6.75751 9.46982 5.9171C9.70546 5.49347 9.98207 5.1006 10.3714 4.79996C11.0817 4.24993 11.915 4.28751 12.5672 4.90587C12.8746 5.19625 13.1034 5.5413 13.2673 5.92735C14.1074 7.88148 14.9645 9.8322 15.7739 11.8C16.5833 13.7644 15.1182 15.9406 12.9941 15.9918C12.9531 15.9918 12.9087 15.9952 12.8677 15.9952C11.2422 15.9952 9.61667 15.9952 7.98772 15.9918Z"
                fill="#D9D9D9"
              />
              <path
                d="M1.54362 2.59302C1.55045 1.14791 2.71497 -0.00680247 4.15951 3.01662e-05C5.60064 0.00686281 6.76173 1.18208 6.7549 2.62035C6.74466 4.04837 5.5699 5.21333 4.14243 5.20992C2.70472 5.20992 1.54021 4.0347 1.54362 2.59302Z"
                fill="#D9D9D9"
              />
            </svg>
            Logo
          </Link>
        </div>

        <Container>
          <div>
            <form>
              <Row>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label>First name</label>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      name="firstname"
                      placeholder="John"
                      value={formik.values.firstname}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label for="exampleInputPassword1">Last name</label>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      name="lastname"
                      placeholder="Smith"
                      value={formik.values.lastname}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label>Company Name</label>
                    </div>
                    <input
                      type="text"
                      class="form-control"
                      name="companyrepresentative"
                      placeholder="XYZ"
                      value={formik.values.companyrepresentative}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label>Work email</label>
                    </div>
                    <input
                      type="email"
                      class="form-control"
                      name="email"
                      value={formik.values.email}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label>Registered Address</label>
                    </div>
                    <input
                      type="search"
                      class="form-control"
                      placeholder="Search Here"
                      name="address1"
                      value={formik.values.address1}
                    />
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group mb-4">
                    <div className="d-flex justify-content-between">
                      <label>Phone number</label>
                    </div>
                    <div className="phone-select d-flex">
                      <select
                        class="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>+44</option>
                      </select>
                      <input
                        type="text"
                        class="form-control"
                        name="mobile"
                        value={formik.values.mobile}
                      />
                    </div>
                  </div>
                </Col>

                <Col lg={6}>
                  <div class="form-group">
                    <div className="d-flex justify-content-between">
                      <label>Logo</label>
                    </div>
                    <div className="img-upload">
                      <input type="file" onChange={handleChange} />
                      <img
                        src={upfile == "" ? file : upfile}
                        crossOrigin="anonymous"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={6}>
                  <div class="form-group select-color">
                    <div className="d-flex justify-content-between">
                      <label>Club Color</label>
                    </div>
                    <input
                      type="color"
                      class="form-control"
                      placeholder=""
                      name="confirmpassword"
                      value={formik.values.color}
                    />
                  </div>
                </Col>
                <Col lg={8}>
                  <div class="form-group mt-4">
                    <div className="d-flex justify-content-between">
                      <label>Social Media</label>
                    </div>
                    <div className="social-icons mt-3">
                      <Link to={formik.values.facebooklink} target="_blank">
                        <img src={require("../assets/images/fb.svg").default} />
                      </Link>
                      <Link to={formik.values.twitterlink} target="_blank">
                        <img src={require("../assets/images/x.svg").default} />
                      </Link>
                      <Link to={formik.values.instralink} target="_blank">
                        <img
                          src={require("../assets/images/insta.svg").default}
                        />
                      </Link>
                      <Link to={formik.values.theradlink} target="_blank">
                        <img
                          src={require("../assets/images/alogo.svg").default}
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </form>
          </div>
        </Container>
        <div className="cmn-color-btn">
          <button type="button" onClick={handleClick}>
            Edit Profile
          </button>
        </div>
      </Layout>
    </>
  );
}
