import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Form, Link, NavLink, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from 'yup'
import { LoginclubAction } from "../Redux/Action/Authaction";
import { useDispatch } from "react-redux";
export default function Login() {
  const navigate=useNavigate();
  const dispatch=useDispatch();
  const formik=useFormik({
    initialValues:{
      email:"",
      password:"",

    },
    validationSchema:Yup.object().shape({
      email:Yup.string().email("Please enter a valid email").required('Email is required'),
     
      password: Yup.string().required('Password is required')
    }),
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit:(values)=>{
    
      dispatch(LoginclubAction(values))
    }
  })
  return (
    <div>
      <section>
        <Container fluid>
          <Row>
            <Col xl={4} lg={5} md={6} className="white-sec login">
              <div>
                <Row className="second-sec">
                  <Col lg={12}>
                    <h2 className="cmn-heading">Log in</h2>
                  </Col>
                  <Col lg={12}>
                    <p className="sub-heading">
                      Please input your information in the fields below to enter
                      your portal
                    </p>
                  </Col>
                </Row>
                <hr className="cmn-border" />
                <form onSubmit={formik.handleSubmit}>
                  <Row>
                    <Col lg={12}>
                      <div class="form-group">
                        <div className="d-flex justify-content-between">
                          <label>Account</label>
                          <span>
                            <img
                              src={require("../assets/images/info.svg").default}
                            />
                          </span>
                        </div>
                        <div className="mail-img">
                          <img
                            src={
                              require("../assets/images/account-one.svg")
                                .default
                            }
                          />
                          <input
                            type="email"
                            class="form-control"
                            placeholder="Email"
                       
                            onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="email"
                              value={formik.values.email}
                            />
                            {formik.submitCount > 0 && formik.errors.email && (
                              <div className="errors">{formik.errors.email}</div>
                            )}
                        </div>
                        <div className="mail-img">
                          <img
                            src={
                              require("../assets/images/password.svg").default
                            }
                          />
                          <input
                            type="password"
                            class="form-control"
                            placeholder="Password"
                      
                            onBlur={formik.handleBlur}
                              onChange={formik.handleChange}
                              name="password"
                              value={formik.values.password}
                            />
                            {formik.submitCount > 0 && formik.errors.password && (
                              <div className="errors">{formik.errors.password}</div>
                            )}
                        </div>
                        <button className="remember">
                          <Link to="/forgotpassword">
                            Forgot your password?
                          </Link>
                        </button>
                      </div>
                    </Col>

                    <Col lg={12}>
                      <hr className="cmn-border" />
                    </Col>

                    <Col lg={6}>
                      <div class="form-check">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="exampleCheck1"
                        />
                        <label
                          class="form-check-label sub-heading"
                          for="exampleCheck1"
                        >
                          Remember me
                        </label>
                      </div>
                    </Col>
                    <Col lg={6} className="d-flex justify-content-end">
                      <button type="submit" class="cmn-btn">
                        Log in
                      </button>
                    </Col>
                  </Row>
                </form>
              </div>
              <div>
                <Col lg={12}>
                  <div className="last-sec">
                    <p className="sub-heading">Don’t have an account yet?</p>
                    <button className="remember">
                      <Link to="/buisnesselect/newregistration">Register here</Link>
                    </button>
                  </div>
                </Col>
              </div>
            </Col>
            <Col xl={8} lg={7} md={6} className="cmn-background">
              <div className="logo-center">
                <img
                  src={require("../assets/images/cluberly-white.svg").default}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </div>
  );
}
