import React from "react";
import Layout from "../Components/Layout";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function MarketPlaceSetting() {
  return (
    <Layout>
      <Container fluid>
        <div className="top-bar d-flex">
          <img src={require("../assets/images/market.svg").default} />
          <h2>Marketplace</h2>
        </div>
        <div className="profile-tab marketplace">
          <div className="d-flex">
            <Link
              to="/product-overview"
              className={
                window.location.pathname == "/product-overview"
                  ? "profile-link active-tab"
                  : "profile-link "
              }
            >
              {" "}
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.1761 0.0236674C10.1761 0.0931988 10.1761 0.155411 10.1761 0.217623C10.1761 1.568 10.1761 2.91837 10.1761 4.26874C10.1761 5.21291 10.7876 5.82039 11.736 5.82039C13.0836 5.82039 14.4275 5.82039 15.775 5.82039C15.8409 5.82039 15.9105 5.82039 15.991 5.82039C15.9947 5.88992 15.9984 5.94482 15.9984 5.99971C15.9984 8.10029 16.002 10.2009 15.9984 12.3015C15.9947 14.0946 14.8229 15.5548 13.0872 15.9171C12.8273 15.972 12.5563 15.9939 12.2926 15.9939C10.1578 16.0013 8.0193 16.0013 5.88447 15.9976C3.00263 15.9939 0.64077 14.0397 0.0988236 11.2182C-0.344255 8.93101 0.732316 6.49375 2.73166 5.2678C2.86348 5.18729 2.91841 5.10312 2.91109 4.94576C2.9001 4.51393 2.90376 4.08211 2.90743 3.65028C2.92574 1.65583 4.42707 0.0822202 6.42276 0.0200079C7.64946 -0.0202472 8.87617 0.0126888 10.1029 0.0126888C10.1212 0.00902921 10.1395 0.0163483 10.1761 0.0236674ZM10.1761 10.2009C10.1798 7.79289 8.23901 5.83503 5.83687 5.82405C3.4274 5.81673 1.46101 7.75629 1.45003 10.1496C1.44271 12.5832 3.39078 14.5411 5.82222 14.5448C8.21338 14.5448 10.1724 12.5906 10.1761 10.2009Z"
                  fill="#42505C"
                />
                <path
                  d="M11.6445 4.35291C11.6445 3.06109 11.6445 1.77659 11.6445 0.510387C12.9261 1.79123 14.2114 3.07573 15.4894 4.35291C14.2297 4.35291 12.9481 4.35291 11.6445 4.35291Z"
                  fill="#42505C"
                />
                <path
                  d="M6.53627 11.6318C6.53627 11.8769 6.53994 12.1185 6.53627 12.3637C6.52529 12.7809 6.2177 13.0846 5.81124 13.0846C5.40478 13.0846 5.09718 12.7809 5.08986 12.36C5.08254 11.8733 5.08254 11.3829 5.08986 10.8962C5.09718 10.49 5.4121 10.1826 5.80391 10.1789C6.20671 10.1753 6.52163 10.4827 6.53261 10.8962C6.5436 11.145 6.53627 11.3902 6.53627 11.6318Z"
                  fill="#42505C"
                />
                <path
                  d="M5.81124 7.28055C6.4191 7.28055 6.90612 7.76727 6.90245 8.3711C6.89879 8.97126 6.40445 9.4653 5.80391 9.45798C5.20338 9.45432 4.71636 8.95662 4.72002 8.3528C4.72734 7.75995 5.21436 7.28055 5.81124 7.28055Z"
                  fill="#42505C"
                />
              </svg>
              Product Overview
            </Link>
            <Link
              to="/marketplace-setting"
              className={
                window.location.pathname == "/marketplace-setting"
                  ? "profile-link active-tab"
                  : "profile-link"
              }
            >
              {" "}
              <svg
                width="14"
                height="16"
                viewBox="0 0 14 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.344632 16C0.0680622 15.8719 -0.00514735 15.6512 0.000275581 15.3549C0.0551828 12.4838 2.23385 9.93214 5.04767 9.46894C5.3988 9.41104 5.7574 9.38652 6.11395 9.37903C6.74573 9.36541 7.37885 9.35587 8.00995 9.37971C10.4069 9.46826 12.1619 10.6113 13.3109 12.7059C13.763 13.5301 13.9596 14.4306 13.9989 15.3706C14.0111 15.6635 13.9237 15.8747 13.6552 16C9.2179 16 4.78127 16 0.344632 16Z"
                  fill="#C4C4C4"
                />
                <path
                  d="M6.99721 8.43697C4.68162 8.43561 2.7958 6.53514 2.80122 4.20827C2.80664 1.88752 4.69179 -0.0013616 7.00196 7.36468e-07C9.31823 0.00136308 11.2034 1.90115 11.198 4.2287C11.1925 6.54945 9.30738 8.43833 6.99721 8.43697Z"
                  fill="#C4C4C4"
                />
              </svg>
              Marketplace Settings{" "}
            </Link>
          </div>
          <div className="total-raised">
            <h2>Total Raised: £24,849.00</h2>
          </div>
        </div>
        <div className="market-setting">
          <Row>
            <Col xl={9} lg={8}>
              <h2>Make Changes to your Merchandise Design</h2>
              <p>
                If you want to make changes to your Organisation Logo, you can
                do this within your profile tab, under ‘Logo’.
                <br /> You can also update the name of your or Organisation in
                the Profile Tab.
              </p>
              <p>
                If you’re looking for more customisation for your merchandise,
                please contact us.
                <br /> Some requests may not be possible.
              </p>
            </Col>
            <Col xl={3} lg={4}>
              {" "}
              <Link to="/editprofile" className="act-btn">
                Update Profile
              </Link>
              <Link to="#" className="act-btn">
                Contact Us
              </Link>
            </Col>
          </Row>
        </div>
        <div className="market-setting">
          <Row>
            <Col xl={9} lg={8}>
              <h2>Removing Presence from the Marketplace</h2>
              <p>
                If you want no longer want to be part of the Marketplace, you
                can de-activate this by tapping this button.
                <br /> We would appreciate any feedback during your cancellation
                process.
              </p>
            </Col>
            <Col xl={3} lg={4}>
              {" "}
              <Link to="#" className="act-btn">
                Remove from Marketplace
              </Link>
            </Col>
          </Row>
        </div>
      </Container>
    </Layout>
  );
}
