import { createSlice } from "@reduxjs/toolkit";
import { AdminlistAction, ProfiledetailsAction, UserlistAction, accountsuspendedAction, addandupdateblogAction, addandupdateclubAction, blogdetailsAction, clubdetailsAction, getbloglistAction, getclublistAction, memberprofiledetailsAction } from "../Action/AdminstratorAction";
import { categoryandsubcategorylistAction } from "../Action/Authaction";
// Define an initial state
const initialState = {
    Adminlist: null,
    loading: 'idle',
    error: null,
    admindetails:"",
    memberdetails:"",
    clubdetails:"",
    memberlists:[],
    getclublists:[],
    getbloglists:[],
    blogdetails:"",
    categorysub:[]
  };



const AdministratorSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
      // You can include synchronous actions here if needed
    },
    extraReducers: (builder) => {
      builder
      .addCase(AdminlistAction.pending, (state) => {
        state.loading = 'pending';
      })
      .addCase(AdminlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.Adminlist = action.payload;
      }).
      addCase(ProfiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.admindetails = action.payload;
      })
      
      .addCase(UserlistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberlists = action.payload;
      })
      .addCase(memberprofiledetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(categoryandsubcategorylistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
     
        state.categorysub = action.payload;
      })

      .addCase(addandupdateclubAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      
      .
      addCase(accountsuspendedAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.memberdetails = action.payload;
      })
      .addCase(getclublistAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.getclublists = action.payload;
      })
      .addCase(clubdetailsAction.fulfilled, (state, action) => {
        state.loading = 'fulfilled';
        state.clubdetails = action.payload;
      })
      


      
      .addCase(AdminlistAction.rejected, (state, action) => {
        state.loading = 'rejected';
        state.error = action.error.message;
      })
      


        //blogs section
        .addCase(getbloglistAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.getbloglists = action.payload;
        })
          .addCase(blogdetailsAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.blogdetails = action.payload;
        })
        
        .addCase(addandupdateblogAction.fulfilled, (state, action) => {
          state.loading = 'fulfilled';
          state.blogdetails = action.payload;
        })
        
        
      
              //blogs section
    },
  });
  
  export default AdministratorSlice.reducer;