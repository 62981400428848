import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { NavLink, useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { AddandupdatebyclubAction, categoryandsubcategorylistAction } from "../Redux/Action/Authaction";
import { clubdetailsAction } from "../Redux/Action/AdminstratorAction";
import { toast } from "react-toastify";
import { useFormik } from 'formik';
import * as Yup from 'yup';

export default function ClubSelect({ formData, setFormData, nextStep,categorylist,subcategory_id,category_id}) {

        var subcategory=    categorylist?.subcategory?.filter(
              (subcategory) =>
                subcategory?.category_id?.toString() ===
                formData?.category_id?.toString()
            );



            const formik = useFormik({
              initialValues: {
                subcategory_id: formData.subcategory_id || category_id,
             
              },
              validationSchema: Yup.object({
                subcategory_id: Yup.string().required('Club type  is required'),
                
              }),
              onSubmit: values => {
          
           
                  setFormData({
                    ...formData,
                    ...values,
                  });
             
          
                  nextStep();
            
                }
          
                // Handle step 1 submission
              
            });
          
          
          
    

            

            const handleFormChange = (e) => {
              // Use Formik's handleChange to update form state
              formik.handleChange(e);
              
              // Update formData state with the changed value
              setFormData({
                ...formData,
                [e.target.name]: e.target.value,
              });
            };




  return (
    <div>
      {" "}
      <section className="cmn-background buisness">
        <Container fluid>
          <Row className="justify-content-center">
            <div className="white-sec">
              <Row>
                <Col lg={12} className="d-flex justify-content-center">
                  <div className="logo">
                    <img
                      src={require("../assets/images/Logo.png")}
                      className="img-fluid"
                    />
                  </div>
                </Col>
                <form onSubmit={formik.handleSubmit}>
                <Col lg={12}>
                  <div className="select-card">
                    <h2>SELECT CLUB TYPE </h2>
                    <div className="select-lists width-set">
                      <Row>
                      {
                        subcategory?.map(function(object,i){
                        return(
                          <Col lg={3} md={4} sm={4} xs={12}>
                          <div className="list-inner-main mb-4">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="subcategory_id"
                              id="flexRadioDefault1"
                              value={object?._id}
                              onClick={handleFormChange}
                       
                              checked={formData?.subcategory_id?.toString() == object?._id?.toString()}

                            />
                            <div className="list-inner">
                           
                            <img
                             
                              src={ process.env.REACT_APP_IMAGE_URL+"charity/"+ object?.icon
                              
                              }
                              crossOrigin ="anonymous" 
                            />
                            </div>
                            <img
                              className="tick"
                              src={
                                require("../assets/images/tick-mark.svg")
                                  .default
                              }
                            />
                            <label
                              class="form-check-label"
                              for="flexRadioDefault1"
                            >
                             {
                              object?.name
                             }
                            </label>
                          </div>
                        </Col>
)
                        })
                      }
                      {formik.submitCount > 0 && formik.errors.subcategory_id && (
                              <div className="errors">{formik.errors.subcategory_id}</div>
                            )}
                              
                      <Col lg={12} className="d-flex justify-content-center">
                          <div className="next-btn">
                            <button
                              type="submit"
                              className="cmn-btn"
                           
                            >
                              NEXT STEP
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
                </form>
              </Row>
            </div>
          </Row>
        </Container>
      </section>
    </div>
  );
}
