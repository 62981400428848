import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";
import { Encryptedid } from "../../Util/BcruptEncyptid";
import { ToastContainer, toast } from "react-toastify";


export const  AddandupdatebyclubAction =createAsyncThunk('addandupdatebyclub',async(obj)=>{

       




        try {
            const  data  = await Api.post("/webportal/addandupdatebyclub", obj);
          
          
      
            if(data?.data?.status==201){
                return data?.data?.saveinformation
         
            
            }else{
            toast.error(data.data.message)
            }
    
        } catch (error) {
         
            toast.error(error.message)
        }


    })



    export const LoginclubAction =createAsyncThunk('Loginclub',async(obj)=>{

        try {
            const  data  = await Api.post("/webportal/Loginclub", obj);
            console.log(data.data)
          
          
      
            if(data?.data?.status==200){
                window.location.href="/dashboard"
                localStorage.setItem('accessToken',data.data.token.token);
                localStorage.setItem('userid',data.data.userdetails._id);
            
            }else{
            toast.error(data.data.message)
            }
    
        } catch (error) {
         
            toast.error(error.message)
        }


    })



    export const  checkotpAction =createAsyncThunk('checkotp',async(obj)=>{

        const  data  = await Api.post("/adminauth/checkotp", obj);
      
        if(data.data.status==200){
            if(data.data.userdetails.resetpasswordotpverify==0){

                const bcrypt = Encryptedid(data.data.userdetails._id);
                window.location.href="/Resetpassword/"+bcrypt;
            }else{
                window.location.href="/dashboard"
                localStorage.setItem('userid',data.data.userdetails._id);
                localStorage.setItem('accessToken',data.data.token.token);
            }
          

        
              
            
           
        }else{
            toast.error(data.data.message)
        }


    })

    

export const  AddadmincreateAction =createAsyncThunk('Addadmincreate',async(obj)=>{

const  data  = await Api.post("/adminauth/addadmincreate", obj);
// console.log(data.data,"data");



})



export const  forgotpasswordAction =createAsyncThunk('forgotpassword',async(obj)=>{

    const  data  = await Api.post("/adminauth/forgotpassword", obj);
  
    if(data.data.status==200){
        console.log(data.data)
      
        const bcrypt = Encryptedid(data.data.userdetails._id);
        window.location.href="/ResetOtp/"+bcrypt;
    
        toast.error(data.data.message)
        
       
    }else{
        toast.error(data.data.message)
    }


})



export const  adminpasswordresetAction =createAsyncThunk('passwordreset',async(obj)=>{

    const  data  = await Api.post("/adminauth/passwordreset", obj);
  
    if(data.data.status==200){
       toast.success("Your password has been reset successfully. You can now log in with your new credentials")

       window.location.href="/";
          
        
       
    }else{
        toast.error(data.data.message)
    }


})


 //send email verification 

 export const sendverificationemailAction =createAsyncThunk('sendverificationemail',async(obj)=>{

    const  data  = await Api.post(`/webportal/sendverificationemail`,obj);

      if(data.data.status==201){
           return data.data.getclublists
      }

})



export const categoryandsubcategorylistAction =createAsyncThunk('categoryandsubcategorylist',async(obj)=>{

    const  data  = await Api.get(`/webportal/categoryandsubcategorylist`);

      if(data.data.status==200){
      

           return data.data.Listcategorysub
      }

})