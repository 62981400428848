
import { createAsyncThunk } from "@reduxjs/toolkit";
import Api from "../ApiConfig";



export const  AdminlistAction =createAsyncThunk('Adminlist',async(obj)=>{

        const  data  = await Api.post("/adminauth/adminlist", obj);

                if(data.data.status==201){

                    return data.data.adminlist
                }
    })


    export const  ProfiledetailsAction =createAsyncThunk('Profiledetails',async(obj)=>{

        const  data  = await Api.get(`/adminauth/profiledetails?id=${obj}`);

                if(data.data.status==201){

                     return data.data.admindetails
                }

    })


    export const  UserlistAction =createAsyncThunk('Userlist',async(obj)=>{

        const  data  = await Api.post("/adminauth/userlist", obj);

                if(data.data.status==201){

                    return data.data.userlist
                }

    })
    

    export const  deleteAdminuserAction =createAsyncThunk('deleteAdminuser',async(obj)=>{

        const  data  = await Api.post(`/adminauth/deleteAdminuser`,obj);

                if(data.data.status==201){

                     return data.data.admindetails
                }

    })


    
    export const memberprofiledetailsAction =createAsyncThunk('memberprofiledetails',async(obj)=>{

        const  data  = await Api.post(`/webportal/memberprofiledetails`,obj);

          if(data.data.status==201){
               return data.data.memberdetails
          }

})


    export const  updatememberprofileAction =createAsyncThunk('updatememberprofile',async(obj)=>{

              const  data  = await Api.post(`/adminauth/updatememberprofile`,obj);

                if(data.data.status==201){
                     return data.data.admindetails
                }

    })


    export const addandupdateclubAction =createAsyncThunk('addandupdateclub',async(obj)=>{

        const  data  = await Api.post(`/adminauth/addandupdateclub`,obj);

          if(data.data.status==201){
               return data.data.saveinformation
          }

     })

export const getclublistAction =createAsyncThunk('getclublist',async(obj)=>{

    const  data  = await Api.post(`/adminauth/getclublist`,obj);

      if(data.data.status==201){
           return data.data.getclublists
      }

})


export const accountsuspendedAction =createAsyncThunk('accountsuspended',async(obj)=>{

     const  data  = await Api.post(`/adminauth/accountsuspended`,obj);
 
       if(data.data.status==201){
            return data.data.userlist
       }
 
 })
 export const clubpicAction =createAsyncThunk('clubpic',async(obj)=>{

     const  data  = await Api.post(`/webportal/clubpic`,obj);
 
       if(data.data.status==201){
            return data.data.image.filename
       }
 
 })



 export const clubdetailsAction =createAsyncThunk('clubdetails',async(obj)=>{

     const  data  = await Api.post(`/webportal/clubdetails`,obj);
 
       if(data.data.status==201){
            return data.data.getclublists
       }
 
 })

 export const deleteclubAction =createAsyncThunk('deleteclub',async(obj)=>{

     const  data  = await Api.post(`/adminauth/deleteclub`,obj);
 
       if(data.data.status==201){
            return data.data.getclublists
       }
 
 })

 //blog action section 

 export const addandupdateblogAction =createAsyncThunk('addandupdateblog',async(obj)=>{
          const  data  = await Api.post(`/adminauth/addandupdateblog`,obj);
               if(data.data.status==201){
                    return data.data.saveinformation
               }
               })
export const deleteblogAction =createAsyncThunk('deleteblog',async(obj)=>{
     const  data  = await Api.post(`/adminauth/deleteblog`,obj);
          if(data.data.status==201){
               return data.data.saveinformation
          }
          })
export const getbloglistAction =createAsyncThunk('getbloglist',async(obj)=>{
     const  data  = await Api.post(`/adminauth/getbloglist`,obj);
          if(data.data.status==201){
               return data.data.getbloglists
          }
          })
export const blogdetailsAction =createAsyncThunk('blogdetails',async(obj)=>{
     const  data  = await Api.post(`/adminauth/blogdetails`,obj);
          if(data.data.status==201){
               return data.data.getblogdetails
          }
          })


 //end blog action section 
 


 
 

 




    


    
    